






























































import {Component, Vue} from 'vue-property-decorator';
import UBlock from '@/ui-lib/block/Block.vue';
import UTextField from '@/ui-lib/text-field/TextField.vue';
import UButton from '@/ui-lib/button/Button.vue';
import UAlert from '@/ui-lib/alert/Alert.vue';
import {auth} from '@/store/modules/auth'

@Component({
    components: {
        UBlock,
        UTextField,
        UButton,
        UAlert,
    }
})
export default class AuthView extends Vue {
    private loading = false;
    private email = '';
    private password = '';

    private errorMessage = '';
    private fails: Record<string, string[]> = {};

    private async onSubmit() {
        this.loading = true;

        try {
            await auth.context(this.$store).actions.login({
                email: this.email,
                password: this.password,
            });

            await this.$router.push('/');
        } catch (e) {
            this.errorMessage = e?.message || 'Ошибка';
            this.fails = e?.validationFails || {};
        }

        this.loading = false;
    }
}
